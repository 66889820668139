import React, { useEffect, useState } from "react";
import './../assets/styles/account.css'
import ApiHandler from "../model/ApiHandler";
import Footer from "./../footer";
import { read_cookie } from "sfcookies";
import Header from "../header";
import { Alert, Button, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";


//Put this back when withdrawal function is fixed as this activated the modal
///  <a href="#" onClick={() => setModalShow(true)}>Click Here</a>

const Account = () => {
    const apiHandler = new ApiHandler();
    const clientApi = read_cookie('client_api');
    const clientName = read_cookie('client_name');

    const [page, setPage] = useState(1);
    const [availableBalance, setAvailableBalance] = useState("0.00");
    const [search, setSearch] = useState("")
    const [transactionData, setTransactionData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const reverseString = str => [...str].reverse().join('');

    useEffect(() => {
        getTransaction();
        getClientBalance();
    }, [])
    useEffect(() => {
        getTransaction();
    }, [page])

    useEffect(() => {
        const result = filterData.filter((d) => {
            if (d.transaction_notes.toLowerCase().match(search.toLowerCase())) {
                return d.transaction_notes.toLowerCase().match(search.toLowerCase())
            }
            else if (d.amount.toLowerCase().match(search.toLowerCase())) {
                return d.amount.toLowerCase().match(search.toLowerCase())
            }
        })
        setTransactionData(result)
    }, [search]);

    const getClientBalance = () => {
        apiHandler.clientBalance(clientApi, (message, data) => {

            setAvailableBalance((data.current_account_balance / 100).toFixed(2))
        }, (data) => {
            console.log(data)
            console.log("")
        });
    };

    const getTransaction = () => {
        apiHandler.transactions(clientApi, page, (message, data) => {
            // setError("")
            // setSuccess(message)
            setTransactionData(data.transactions)
            setFilterData(data.transactions)
        }, (message) => {
            console.log(message)
            console.log("")
        });
    };

    const Withdraw = () => {
        let form = document.getElementsByTagName("form")[0]
        if (!form.checkValidity()) {
            form.reportValidity();
            return;
        }
        apiHandler.withdrawal(clientApi, withdrawAmount, (message, data) => {
            console.log(message)
            setError("")
            setSuccess(message)
            setModalShow(false)
        }, (message) => {
            setError(message)
            setSuccess("")
            setModalShow(false)
        });
    };

    const setShow = () => {
        setError("");
        setSuccess("");
    }

    const next = () => {
        setPage((prevCount) => {
            return (prevCount += 1);
        });
    }

    const prev = () => {
        setPage((prevCount) => {
            if (prevCount > 1) {
                return (prevCount -= 1);
            } else {
                return (prevCount = 1);
            }
        });
    }

    return (
        <>
            {/* <section className='main-header account-header'>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div className="logo-account">
                                <a href="/"><img src={require('../assets/images/logo.png')} /></a>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div className="profile-account">
                                <p> <span>Good Morning, John Day</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Header />

            <section className="account-comapny">
                <div className="container">
                    <img src={require('../assets/images/account-01.png')} />
                    <h2><span> {clientName}</span></h2>
                    <p>Your API key - (<b>{clientApi}</b>)</p>
                    <Alert onClose={() => setShow()} show={(success !== "") ? true : false} variant="success" dismissible>
                        {success}
                    </Alert>
                    <Alert onClose={() => setShow()} show={(error !== "") ? true : false} variant="danger" dismissible>
                        {error}
                    </Alert>
                </div>
            </section>

            <section className="account-box-fund">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                            <div className="box-transfer">
                                <h3>Available Balance</h3>
                                <span>${availableBalance}</span>
                                <img src={require('../assets/images/acc-01.png')} />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                            <div className="box-transfer greyout-box">
                                <h3>Available USDC</h3>
                                <span>Disabled</span>
                                <img src={require('../assets/images/acc-02.png')} />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                            <div className="box-transfer greyout-box">
                                <h3>Create a payout</h3>
                                <a href="#">Click Here</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                            <div className="box-transfer greyout-box">
                                <h3>Withdrawal funds</h3>
                                <a href="#">Click Here</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="transection-table" style={{ marginBottom: 60 }}>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6 text-start'>
                            <h3>Transactions</h3>
                        </div>
                        <div className='col-md-6 text-end'>
                            <div className="search-drop">
                                <div className="p-1 bg-light rounded rounded-pill shadow-sm">
                                    <div className="input-group">
                                        <input type="search" disabled="disabled" placeholder="   Search Transactions" aria-describedby="button-addon1" value={search} onChange={(e) => { setSearch(e.target.value) }} className="form-control border-0 bg-light" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 25, paddingBottom: 25 }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Transaction ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionData.map((d) => {
                                    const dateStr = new Date(d.date_created).toDateString();
                                    const dateStrArr = dateStr.split(' ');;
                                    return (
                                        <tr key={d.id}>
                                            <td scope="row">{dateStrArr[1] + ' ' + dateStrArr[2] + ', ' + dateStrArr[3]}</td>
                                            <td>{d.transaction_notes}</td>
                                            <td style={{ color: d.transaction_type_id == 5 ? "green" : "red" }}>{d.transaction_type_id == 5 ? "+" : "-"} ${(d.amount / 100).toFixed(2)}</td>
                                            <td style={{ color: d.transaction_type_id == 5 ? "green" : "red" }}>{d.transaction_type_id == 5 ? "Payment" : "Withdrawal"}</td>
                                            <td>{reverseString(d.transaction_id)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="row align-center">
                        <div className='buton-hire col-md-6 text-start'>
                            <a onClick={prev} style={{ cursor: 'pointer' }}>Prev</a>
                        </div>
                        <div className='buton-hire col-md-6 text-end'>
                            <a onClick={next} style={{ cursor: 'pointer' }}>Next</a>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="widraw_form"
            >
                <section className="form-custom ">
                    <form action="#" method="post">
                        <div className="bg-form-inner">
                            <h3>Withdraw Form</h3>
                            <TextField
                                required
                                id="demo-helper-text-misaligned-no-helper"
                                label="Add Amount"
                                onChange={(e) => setWithdrawAmount(e.target.value)}
                                value={withdrawAmount}
                            />

                            <div className="center-butn">
                                <div className='buton-hire col-md-12 text-center'>
                                    <a onClick={Withdraw} style={{ cursor: 'pointer' }}>Withdraw</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </Modal>
        </>
    )
}

export default Account;