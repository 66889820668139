import React from "react";
import './../assets/styles/home.css'
import OwlCarousel from 'react-owl-carousel';
import './../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import './../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import Header from "../header";
import Footer from "../footer";


const HomeScreen = () => {

    return (

        <>
            <Header />
            <section className='banner-section'>
                <div className='container'>
                    <div className="width-custom" data-aos="fade-up" data-aos-duration="2000">
                        <span>Self-service merchant processing, reimagined.</span>
                        <h1>Supercharge your business with <span className='yirePay-color'>yirePay</span></h1>
                        <p>yirePay is a comprehensive suite of payment processing services, that enables businesses to accept and send online payments across the globe and can revolutionize the way you do business. YirePay offers a robust set of open APIs that allow you to integrate and customize your payment and payout processing solutions to your specific requirements.</p>
                        <ul>
                            <li><a href='/signup'>Get Started</a></li>
                            <li><a href='#' className="second-bottn">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="cloud">  </section>



            <section className="why-yirepay">
                <div className="container">
                    <div className="heading-div">
                        <h2>Merchant Solutions</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                            <div className="box-custom" data-aos="fade-right" data-aos-duration="1000">
                                <img src={require('../assets/images/1.png')} />
                                <h3>Boutique and Online Shops</h3>
                                <p>Integrate our service directly into your website for fast and easy processing.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                            <div className="box-custom">
                                <img src={require('../assets/images/2.png')} />
                                <h3>Small Business and Storefront</h3>
                                <p>Accept in person payments via terminal or POS integration</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                            <div className="box-custom" data-aos="fade-left" data-aos-duration="1000">
                                <img src={require('../assets/images/3.png')} />
                                <h3>Loyalty, Rewards, and Promo Codes</h3>
                                <p>Create custom loyalty programs to drive customer engagement</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-about">
                <div className="container">
                    <div className="content-middle">
                        <img src={require('../assets/images/circle-logo.png')} />
                        <h2>About yirePay</h2>
                        <p>
                            yirePay offers a comprehensive range of merchant solutions that cater to all your payment processing needs. Our payment processing solutions are designed to be flexible, secure, and reliable, allowing you to accept all major credit and debit cards, as well as alternative payment methods like contactless (ApplePay, GooglePay, QR code), and Crypto (USDC).

                            <br></br><br></br>In addition to payment processing, we offer point-of-sale integrations that are aimed at making transactions seamless and secure, with your current hardware, and e-commerce solutions so that you can expand your online sales channels and reach more customers than ever before. Our suite can talk directly to major and even custom POS systems with our fully open APIs.

                            <br></br><br></br>Our integrated software comes equipped with a range of features that allow you to engage with your customers in new and innovative ways. One of these features is our custom loyalty program, which supports earning and redemption. Our loyalty program is designed to help you increase customer engagement and retention by rewarding your customers for their loyalty. With our loyalty program, your customers can earn points for every purchase they make, which they can then redeem for discounts or other rewards.

                            <br></br><br></br>Moreover, we also offer the ability to create custom promo codes that can be used to promote your business and attract new customers. With our promo code feature, you can create unique codes that offer discounts or other promotional offers to your customers.

                            <br></br><br></br>Whether you're a small business owner or a large enterprise, we have the solutions you need to thrive in today's competitive marketplace. Contact us today to learn more about how we can help your business succeed.
                        </p>
                        <a href='#'>CONTACT US</a>
                    </div>
                </div>
            </section>


            <section className="double-colmun">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="left-content" data-aos="fade-right" data-aos-duration="1000">
                                <h2>Need a full solution?</h2>
                                <p>If you're a customer who is looking for a more customized solution or you're not sure where to begin, we have a team of experienced full stack developers on staff who can work with you to create a solution that meets your specific pricing and needs. Our developers are skilled in a wide range of programming languages and have experience working with a variety of platforms and tools.</p>
                                <p>In addition to our development team, we also have a dedicated team of account managers who are available to help you integrate our services with any other platform or service you may be using, such as POS systems, financing platforms, and analytics software. Our account managers have a deep understanding of our products and services, and they can help you navigate the integration process with ease.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="right-img" data-aos="flip-down" data-aos-duration="1000">
                                <img src={require('../assets/images/product.png')} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="particle-view">
                <div className="container" data-aos="zoom-in" data-aos-duration="2000">
                    <img src={require('../assets/images/4.png')} />
                    <h2>Want to play in our sandbox?</h2>
                    <p>If you're interested in playing in our sandbox and testing out our merchant processing services, you'll need to obtain a developer API key. The API key will allow you to access our sandbox environment, where you can experiment with our APIs and familiarize yourself with our platform.<br></br><br></br>

                        To get started, follow these steps:<br></br>

                        1. Click the "Get an API key" below and create an account. You'll need to provide some basic information, such as your name, email address, and company name.<br></br>

                        2. Once you've created your account, navigate to the API documentation section of our website. Here you'll find detailed information about our APIs, including how to authenticate requests and what endpoints are available.<br></br>

                        3. To obtain a developer API key, click the "Generate Key" button. You'll be asked to provide some additional information, such as the name of your application and the type of integration you're planning.<br></br>

                        4. After you've generated your API key, you'll be able to access our sandbox environment. Here, you can create test transactions, experiment with different parameters, and get a feel for how our platform works.<br></br><br></br>

                        It's important to note that our sandbox environment is designed for testing purposes only. Transactions processed in the sandbox do not involve real money, and any data you submit will not be stored in our production system. Once you're ready to start processing live transactions, you'll need to obtain a separate production API key and complete additional steps to ensure compliance with industry regulations.<br></br><br></br>

                        If you have any questions or need assistance obtaining your developer API key, please don't hesitate to reach out to our support team. We're here to help you get started and ensure your integration goes smoothly.




                    </p>
                    <a href='/get-api-key'>Get an API Key </a>
                </div>
            </section>


            <section className="partner-view">
                <div className="container">
                    <h2>Partners</h2>

                    {/* // className "owl-theme" is optional */}
                    <OwlCarousel className='owl-theme' loop margin={0} items={4}>

                        <div className='item'>
                            <div className="box-tile">
                                <img src={require('../assets/images/Mastercard.png')} />
                            </div>
                        </div>
                        <div className='item'>
                            <div className="box-tile">
                                <img src={require('../assets/images/HP.png')} />
                            </div>
                        </div>
                        <div className='item'>
                            <div className="box-tile plaid-img">
                                <img src={require('../assets/images/plaid.png')} />
                            </div>
                        </div>
                        <div className='item'>
                            <div className="box-tile">
                                <img src={require('../assets/images/Mastercard.png')} />
                            </div>
                        </div>

                    </OwlCarousel>

                </div>
            </section>
            <Footer />
        </>


    )
}

export default HomeScreen;