import React, { useEffect, useState } from "react";
import './../assets/styles/signin.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ApiHandler from "../model/ApiHandler";
import Header from "../header";
import Footer from "../footer";
import { bake_cookie } from "sfcookies";
import { Navigate } from "react-router-dom";
import { Alert } from "react-bootstrap";

const Signin = () => {
    const apiHandler = new ApiHandler();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")

    const setShow = () => {
        setError("");
        setSuccess("");
    }

    const loginAction = () => {
        let form = document.getElementsByTagName("form")[0]
        if (!form.checkValidity()) {
            form.reportValidity();
            console.log("fomamsdm")
            return;
        }
        apiHandler.login(email, password, (message, data) => {
            if (remember && email !== "") {
                localStorage.username = email;
                localStorage.password = password;
                localStorage.checkbox = remember;
            }
            setError("");
            setSuccess(message);
            setIsLoggedIn(true)
            bake_cookie("client_name", data.client_name);
            bake_cookie("client_balance", data.client_balance);
            bake_cookie("client_api", data.client_api);
            window.location.href = "/account";
        }, (message) => {
            setError(message)
            setSuccess("")
            setIsLoggedIn(false)
        });
    }

    useEffect(() => {
        if (localStorage.checkbox && localStorage.email !== "") {
            setRemember(true);
            setEmail(localStorage.username);
            setPassword(localStorage.password)
        }
    }, [])

    return (
        <>
            <Header />
            <section className="main-banner">

                <div className="row">
                    <div className="col-md-6 col-lg-6 col-xs-12">
                        <div className="left-img">
                            <div className="content-top">
                                <img src={require('../assets/images/white-logo.png')} />
                                <h2>Welcome to yirePay</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xs-12">
                        <div className="right-form-sign">
                            <div className="container">
                                <span>Don't have an account yet?   <a href="/signup">SIGN UP</a></span>
                            </div>
                            <form action="#" method="post">
                                <div className="form-section-div">
                                    <Alert onClose={() => setShow()} show={(success !== "") ? true : false} variant="success" dismissible>
                                        {success}
                                    </Alert>
                                    <Alert onClose={() => setShow()} show={(error !== "") ? true : false} variant="danger" dismissible>
                                        {error}
                                    </Alert>
                                    <h2>Sign in to your yirePay Account</h2>
                                    {/* <TextField id="demo-helper-text-misaligned-no-helper" label="E-mail" InputLabelProps={{ shrink: false }} /> */}
                                    <TextField
                                        id="demo-helper-text-misaligned-no-helper"
                                        label="E-mail"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        required />
                                    <TextField
                                        id="demo-helper-text-misaligned"
                                        label="Password" type="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        required />
                                    <FormControlLabel
                                        control={<Checkbox checked={remember}
                                            onChange={(e) => { setRemember(!remember) }} />}
                                        label="Remember Me" />
                                    <div className="center-butn"><Button variant="contained" onClick={() => loginAction()}>Sign In</Button></div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default Signin;