import React from "react";
import { Link } from "react-router-dom";
import { delete_cookie, read_cookie } from "sfcookies";
import './assets/styles/header.css'
import './App.css';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.min.js'
import 'aos/dist/aos.css';
import mdb from 'mdb-ui-kit';

const client_id = read_cookie("client_api");
const ApiHeader = () => {

    const handleLogout = () => {
        delete_cookie("client_name");
        delete_cookie("client_balance");
        delete_cookie("client_api");
        window.location.href = "/sign-in";
    }
    return (

        <section className='main-header'>
            <div className="container">
                <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/"><img src={require('./assets/images/logo.png')} /></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto mb-2 mb-lg-0 justify-content-center">
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/account"><span>Open a yirepay Account</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/get-api-key"><span>Get an API Kay </span></Link>
                                </li>
                            </ul>
                        </div>
                        <div className='buton-hire'>
                            {typeof client_id == "string" ?
                                <a onClick={handleLogout}>Sign Out</a>
                                :
                                <Link to='/sign-in'>Sign In</Link>
                            }
                        </div>
                    </div>
                </nav>
            </div>
        </section>

    )
}

export default ApiHeader;