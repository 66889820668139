import React from "react";
import './../assets/styles/api.css'
import { Link } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faArrowUpRightFromSquare, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import ApiHeader from "../apiHeader";

const Apireference = () => {


    return (


        <>
        <ApiHeader />
            {/* <section className='main-header'>
                <div className="container">
                    <nav className="navbar navbar-expand-lg ">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/"><img src={require('../assets/images/logo.png')} /></Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav  right-bar-menu">

                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to=""><span>Home</span></Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to=""><span>Open a yirepay Account</span></Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to=""><span>Get an API Kay</span></Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section> */}

            <section className="search-button">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className="dropdown-custom">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                    <Select>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="search-drop">

                                <div class="p-1 bg-light rounded rounded-pill shadow-sm">
                                    <div class="input-group">
                                        <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon1" class="form-control border-0 bg-light" />
                                        <div class="input-group-append">
                                            <button id="button-addon1" type="submit" class="btn btn-link text-primary"><i class="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bor-custom-desing"> </section>

            <section className="three-div-design">
                <div className="container">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-2 collapse d-md-flex pt-2 min-vh-100" id="sidebar">

                                <ul class="nav flex-column ">
                                    <h3>API OVERVIEW</h3>
                                    <li class="nav-item">
                                        <a class="nav-link collapsed" href="#submenu1" data-toggle="collapse" data-target="#submenu1">Health</a>
                                        <div class="collapse" id="submenu1" aria-expanded="false">
                                            <ul class="flex-column pl-2 nav submenu-drop">
                                                <li class="nav-item"><a class="nav-link py-0" href="#">Ping</a> <span>Get</span></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link collapsed" href="#submenu2" data-toggle="collapse" data-target="#submenu1">Reports</a>
                                        <div class="collapse" id="submenu2" aria-expanded="false">
                                            <ul class="flex-column pl-2 nav submenu-drop">
                                                <li class="nav-item"><a class="nav-link py-0" href="#">Orders</a></li>
                                                <li class="nav-item">
                                                    <a class="nav-link collapsed py-1" >Customers</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="nav-item"><a class="nav-link" href="#">Analytics</a></li>
                                    <li class="nav-item"><a class="nav-link" href="#">Export</a></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pt-2" id="health-ping">
                                <h2>Ping</h2>
                                <p><span>Get</span>url here</p>
                                <p className="border-call">Checks that the service is running.</p>
                                <h3>YOUR REQUEST HISTORY</h3>
                                <div className="calls-record">
                                    <h4>0 Calls<span><FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                        <Select>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl></span></h4>
                                    <p>Your API calls will appear here. Make a request to get started!</p>
                                </div>

                                <h3>YOUR REQUEST HISTORY</h3>
                                <div className="calls-record">
                                    <h4 className="after-circle">200<b><button data-toggle="modal" data-target="#exampleModal"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button></b></h4>
                                    <p>Successful ping.</p>
                                </div>


                                <div className="last-updated">
                                    <p><FontAwesomeIcon icon={faClock} /> Last updated few hours <FontAwesomeIcon icon="fa-solid fa-watch" /></p>
                                </div>


                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                    <div className="left-pagination">
                                     <p><FontAwesomeIcon icon={faClock} /> Previous</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <div className="right-pagination">
                                     <p> Next <FontAwesomeIcon icon={faClock} /></p>
                                    </div>
                                </div>
                            </div>


                                <div class="PageThumbs">
                                   <div className="custom-inline-row"><p>Did this page help you? <a href="#"><FontAwesomeIcon icon={faClock} />Yes</a> <a href="#"><FontAwesomeIcon icon={faClock} /> No</a></p></div>
                                </div>

                            </div>

                            <div class="col-md-4 pt-2" id="right-content">
                                
                            </div>

                        </div>
                    </div>

                </div>
            </section>







            {/* <!-- Modal here --> */}


            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            ...
                        </div>

                    </div>
                </div>
            </div>

        </>

    )
}

export default Apireference;