import React, { useState } from "react";
import './../assets/styles/signup.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ApiHandler from "../model/ApiHandler";
import Header from "../header";
import Footer from "../footer";
import { Navigate } from "react-router-dom";
import { Alert } from "react-bootstrap";


const Signup = () => {
    const apiHandler = new ApiHandler();

    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [data, setData] = useState("");
    const [tnc, setTNC] = useState(false);
    const [update, setUpdate] = useState(false);
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")

    const setShow = () => {
        setError("");
        setSuccess("");
    }

    const registerAction = () => {
        let form = document.getElementsByTagName("form")[0]
        if (!form.checkValidity()) {
            form.reportValidity();
            return;
        }
        //console.log(name + '-' + mobile + '-' + address1 + '-', address2 + '-' + city + '-' + state + '-' + zipCode + '-' + email + '-' + password);
        apiHandler.register(name, mobile, address1, address2, city, state, zipCode, email, password, (message, data) => {
            setError("")
            setSuccess(message)
            window.location.href = "/sign-in";
            <Navigate to="/sign-in" />
        }, (message) => {
            setError(message)
            setSuccess("")
        });
    }
    return (
        <>
            <Header />
            <section className="signup-main">
                <div className="container">
                    <img src={require("../assets/images/circle-logo.png")} />
                    <h2>Get started with a yirePay Account</h2>
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                        quae ab illo inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo.
                    </p>
                </div>
            </section>
            <section className="form-custom">

                <form action="#" method="post">
                    <div className="container">
                        <div className="bg-form-inner">
                            <h3>Registration Information</h3>
                            <TextField
                                required
                                id="name demo-helper-text-misaligned-no-helper"
                                label="Organization Name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                inputProps={{ minLength: 5 }}
                            />
                            <TextField
                                required
                                id="last_name demo-helper-text-misaligned-no-helper"
                                // label="Last Name"
                                inputProps={{ minLength: 9 }}
                                label="Mobile Number"
                                onChange={(e) => setMobile(e.target.value)}
                                value={mobile}
                            />
                            <TextField
                                required
                                id="company_name demo-helper-text-misaligned-no-helper"
                                label="Address 1"
                                onChange={(e) => setAddress1(e.target.value)}
                                value={address1}
                                inputProps={{ minLength: 5 }}
                            />
                            <TextField
                                id="company_type demo-helper-text-misaligned-no-helper"
                                label="Address 2 (optional)"
                                onChange={(e) => setAddress2(e.target.value)}
                                value={address2}
                            />
                            <TextField
                                required
                                id="organizatoin_type demo-helper-text-misaligned-no-helper"
                                label="City"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                            // inputProps={{ minLength: 5 }}
                            />
                            <TextField
                                required
                                id="interest_products demo-helper-text-misaligned-no-helper"
                                label="State"
                                // label="Interest Products"
                                onChange={(e) => setState(e.target.value)}
                                value={state}
                                inputProps={{ minLength: 5 }}
                            />
                            <TextField
                                required
                                id="demo-helper-text-misaligned-no-helper"
                                // label="Confirm Password"
                                label="Zip Code"
                                className="full-width"
                                onChange={(e) => setZipCode(e.target.value)}
                                value={zipCode}
                                inputProps={{ minLength: 5 }}
                            />

                            <h3>Login Credentials</h3>
                            <TextField
                                required
                                id="email demo-helper-text-misaligned-no-helper"
                                label="E-mail"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                type="email"
                            />
                            <TextField
                                required
                                id="password demo-helper-text-misaligned-no-helper"
                                label="Password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />

                            <FormControlLabel
                                required={true}
                                control={<Checkbox checked={tnc} onChange={(e) => setTNC(e.target.checked)} required={true} />}
                                label="I agree to YirePay's Terms of Service"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={update} onChange={(e) => setUpdate(e.target.checked)} required={true} />
                                }
                                label=" I agree to receive marketing and other communications from YirePay about the YirePay Products and other company updates."
                            />
                            <Alert onClose={() => setShow()} show={(success !== "") ? true : false} variant="success" dismissible>
                                {success}
                            </Alert>
                            <Alert onClose={() => setShow()} show={(error !== "") ? true : false} variant="danger" dismissible>
                                {error}
                            </Alert>
                            <div className="center-butn">
                                <Button variant="contained" onClick={registerAction}>
                                    Sign Up
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <section className="bottom-bar">
                <div className="container">
                    <h3>Have questions? Contact our sales team</h3>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Signup;