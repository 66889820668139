import React from "react";
import { Link } from "react-router-dom";
import { delete_cookie, read_cookie } from "sfcookies";
import './assets/styles/header.css'
import './App.css';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.min.js'
import 'aos/dist/aos.css';
import mdb from 'mdb-ui-kit';

const client_id = read_cookie("client_api");
const Header = () => {

    const handleLogout = () => {
        delete_cookie("client_name");
        delete_cookie("client_balance");
        delete_cookie("client_api");
        window.location.href = "/sign-in";
    }

    return (

        <section className='main-header'>
            <div className="container">
                <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/"><img src={require('./assets/images/logo.png')} /></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto mb-2 mb-lg-0 justify-content-center">
                                <li className="nav-item dropdown position-static">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                        data-mdb-toggle="dropdown" aria-expanded="false">
                                        Products
                                    </a>
                                    <div className="dropdown-menu w-100 mt-0 cutom-menu-ref" aria-labelledby="navbarDropdown">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                        <p className="mb-0 list-group-item text-uppercase font-weight-bold bold-font-custom">
                                                            Products and Services
                                                        </p>
                                                        <a href="/yirepay" className="list-group-item list-group-item-action">YirePay</a>
                                                        <a href="/yirepayout" className="list-group-item list-group-item-action">YirePay Out</a>
                                                        <a href="/account" className="list-group-item list-group-item-action"> Account</a>

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                        <p className="mb-0 list-group-item text-uppercase font-weight-bold bold-font-custom">
                                                            Third Party Integrations
                                                        </p>
                                                        <a href="" className="list-group-item list-group-item-action">Finance Systems</a>
                                                        <a href="" className="list-group-item list-group-item-action">Point-of-Sale</a>
                                                        <a href="" className="list-group-item list-group-item-action">Instant Payments</a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 no-padding">
                                                    <div className="list-group list-group-flush custom-design-bg">
                                                        <p className="mb-0 list-group-item text-uppercase font-weight-bold bold-font-custom">
                                                            Try it out
                                                        </p>
                                                        <span>Ready to dive in? Grab an API key and head over to our documentation and play in our sandbox. </span>
                                                        <a href="/get-api-key">Get An API Key</a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wide-bg"></div>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="https://yirepay.readme.io/"><span>API Reference</span></a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/developer" ><span>Developers </span></Link>
                                </li>
                            </ul>
                        </div>
                        <div className='buton-hire'>
                            {typeof client_id == "string" ?
                                <a onClick={handleLogout}>Sign Out</a>
                                :
                                <Link to='/sign-in'>Sign In</Link>
                            }
                        </div>
                    </div>
                </nav>
            </div>
        </section>

    )
}

export default Header;