import React from "react";
import './../src/assets/styles/footer.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faDribbble, faBehance } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {

    return (
        <>

            <section className="main-footer">
                <div className="container">
                    <div className="row">

                        <div className="col-xs-12 col-md-3 col-lg-3 col-sm-3">
                            <div className="sec-one">
                                <h3>Products</h3>
                                <ul>
                                    <li><a href='/yirepay'>yirePay</a></li>
                                    <li><a href='/yirepayout'>yirePayout</a></li>
                                    <li><a href='/pricing'>Pricing</a></li>
                                    <li><a href='/account'>Account Management</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-3 col-lg-3 col-sm-3">
                            <div className="sec-one">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><a href='/'>Home</a></li>
                                    <li><a href='/signup'>Signup</a></li>
                                    <li><a href='/privacy'>Privacy Policy</a></li>
                                    <li><a href='/get-api-key'>Sandbox Access</a></li>
                                    <li><a href='/terms'>Terms of Service</a></li>
                                    <li><a href='/contact'>Contact Us</a></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-3 col-lg-3 col-sm-3">
                            <div className="sec-one">
                                <h3>Suscribe to the Newsletter</h3>
                                <div className="input-text">
                                    <TextField id="demo-helper-text-misaligned-no-helper" label="Name" />
                                    <Button variant="contained">Suscribe</Button>
                                </div>
                                <h3 className="social-icon">Contact Us</h3><br />
                                <ul>
                                    <li><a href='#'>info@yirepay.com</a></li>
                                    <li><a href='#'>2970 Belcrest Center Drive, Suite 105</a></li>
                                    <li><a href='#'>Hyattsville, MD 20782</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bottom-bar">
                <div className="container">
                    <div className="custom-text">
                        <p>copyright @ 2023 | ALL RIGHTS RESERVED | yirePay</p>
                    </div>
                </div>
            </section>

        </>

    )
}

export default Footer;
