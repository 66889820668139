import { Button, FormCoolLabel, TextareaAutosize, CircularProgress } from '@mui/material'
import TextField from '@mui/material/TextField';
import React, { useState } from 'react'
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ApiHandler from "../model/ApiHandler";
import Header from "../header";
import Footer from "../footer";
import './../assets/styles/getApikey.css';

export default function GetApikey() {
    const apiHandler = new ApiHandler();

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [purpose, setPurpose] = useState("");
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("");
    const [interstitial, setInterstitial] = useState(false);
    var showinterstitial = false
    const setShow = () => {
        setError("");
        setSuccess("");
    }

    const getKeyAction = () => {
        //setInterstitial(true);
        let form = document.getElementsByTagName("form")[0]
        if (!form.checkValidity() || purpose.length < 30) {
            form.reportValidity();
            setError("Reason should be at least 30 characters.")
            setSuccess("")
            return;
        }
        //console.log(name + '-' + mobile + '-' + address1 + '-', address2 + '-' + city + '-' + state + '-' + zipCode + '-' + email + '-' + password);
        apiHandler.register(name, '----------', purpose, '', '----------', '----------', '00000', email, '-----mvemjsunp-----', (message, data) => {
            //setInterstitial(true);
            setError("")
            setSuccess("API key generated successfully")
            //setInterstitial(false);
            window.location.href = "/get-key-success";
            //<Navigate to="/sign-in" />
        }, (message) => {
            console.log(message);
            //console.log(data);
            if (message == "Client already exist!") {
                let new_message = "Error: An API key has already been generated for this email"
                //setInterstitial(false);
                setError(new_message)
                setSuccess("")
            }
            else {
                //setInterstitial(false);
                setError(message)
                setSuccess("")
            }

        });
    }

    return (
        <>
            <Header />
            <section className="form-custom get-api">
                <form action="#" method="post">
                    <div className="container">
                        <div className="bg-form-inner text-center">
                            <h3>API key Request</h3>
                            <p>In order to use our test API, it is required to request an API key to use.</p>
                            <p><b>Please Note:</b> In order to process live transaction, you will need to create an account.</p>
                            <TextField
                                required
                                id="name demo-helper-text-misaligned-no-helper"
                                label="Organization Name:"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                inputProps={{ minLength: 5 }}
                            />
                            <TextField
                                required
                                id="email demo-helper-text-misaligned-no-helper"
                                label="E-mail"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                type="email"
                            />
                            <TextField
                                id="filled-basic"
                                className='full-width'
                                variant="filled"
                                multiline
                                rows={4}
                                label="Reason for requesting a key"
                                value={purpose}
                                onChange={(e) => setPurpose(e.target.value)}
                                inputProps={{ minLength: 30, maxLength: 100 }}
                            />

                            <Alert onClose={() => setShow()} show={(success !== "") ? true : false} variant="success" dismissible>
                                {success}
                            </Alert>
                            <Alert onClose={() => setShow()} show={(error !== "") ? true : false} variant="danger" dismissible>
                                {error}
                            </Alert>

                            {(!interstitial) ?
                                <div className="center-butn">
                                    <Button variant="contained" onClick={getKeyAction}>
                                        Submit
                                    </Button>

                                </div> :
                                <CircularProgress className="center-butn" color="inherit" />
                            }</div>
                    </div>
                </form>
            </section>
            <Footer />
        </>
    )
}
