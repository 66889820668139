import React from "react";
import Footer from "../footer";
import Header from "../header";
import './../assets/styles/developer.css'



const Developer = () => {

    return (

        <>
            <Header />
            <section className="page-title-custom">
                <div className="container">
                    <div className="upper-text" data-aos="fade-up" data-aos-duration="2000">
                        <img src={require('../assets/images/dev00.png')} />
                        <h1>Developers</h1>
                        <p>
                            yirePay is the ultimate self-service merchant processing platform for developers looking to build custom solutions from the ground up. Our platform is designed to meet the unique payment processing needs of developers in a variety of industries, including restaurants, healthcare, B2B, non-profits, hospitality, and retail.

                            <br /><br />With yirePay, developers have access to a powerful set of tools and resources that enable them to create custom payment processing solutions that meet their specific business goals and objectives. Our open APIs allow for easy integration with existing systems, giving developers the flexibility to create solutions that work seamlessly with their existing infrastructure.

                            <br /><br />Whether you're building a solution for a restaurant that needs to accept payments through an online ordering system or a non-profit organization that needs to manage recurring donations, YirePay can help you meet your payment processing needs. Our platform supports a wide range of payment types, including credit and debit cards, echecks, QR codes, and contactless payments like NFC, Google Pay, and Apple Pay.

                            <br /><br />We understand that security is paramount in the payment processing industry, which is why we offer advanced fraud management and detection services to protect your business from high-risk transactions. Our platform also supports recurring billing or subscriptions, loyalty programs, reward points, discount codes, and promotional offers, making it easy to manage your payment processing needs.

                            <br /><br />If you're a developer looking to build custom solutions from the ground up, get in touch with us today to learn more about how YirePay can help you meet your payment processing needs.
                        </p>
                        <a href='/signup'>Get Started</a>
                    </div>
                </div>
            </section>

            <section className="three-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12 col-lg-6">
                            <div className="developer-content">
                                <span>GET STARTED IN OUR SANDBOX</span>
                                <h2>The smart way for online <br /><span className="color-dev"> Payment Solution.</span></h2>
                                <ul>
                                    <li><img src={require('../assets/images/01.png')} />Flexibility: YirePay provide developers with the flexibility to create solutions that meet their specific business requirements. They can integrate their payment processing needs with their existing systems, ensuring a seamless payment experience for their customers.</li>
                                    <li><img src={require('../assets/images/01.png')} />Customization: YirePay allow developers to customize their payment processing solutions, ensuring that they meet their unique business needs. They can create custom workflows, add new features, and customize the user interface to provide the best possible experience for their customers. </li>
                                    <li><img src={require('../assets/images/01.png')} />Scalability: Yirepay delivers developers with the ability to scale their payment processing solutions as their business grows. They can add new features, integrate new payment methods, and expand their payment processing capabilities to meet the changing needs of their business and customers.</li>
                                </ul>
                                <a href='/get-api-key'>Get an API Key</a>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="dev-img" data-aos="fade-down" data-aos-duration="2000">
                                <img src={require('../assets/images/dev-img.png')} />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="experience">
                <div className="container">
                    <div className="exp-div">
                        <h2>Own your <span>Experience</span></h2>
                        <p>Design your own payments, payouts and account experiences with access to the leading dollar digital currency and our fully programmable APIs.</p>
                        <a href='#'>Contact Our Team</a>
                    </div>
                </div>
            </section>


            <Footer />
        </>

    )
}

export default Developer;