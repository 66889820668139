import React from "react";
import './../assets/styles/yirepayout.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from "../header";
import Footer from "../footer";


const YirepayoutScreen = () => {

    return (

        <>
            <Header />
            <section className="page-title-custom">
                <div className="container">
                    <div className="upper-text" data-aos="fade-up" data-aos-duration="2000">
                        <img src={require('../assets/images/yirepayout.png')} />
                        <h1>yirePayout</h1>
                        <p>YirePayout is the perfect self-service solution for businesses that need to send payouts to their customers, vendors, and suppliers across the world. With YirePayout, you can send payments via ACH, wire transfers, SEPA, or even crypto, depending on your recipient's preference.

                            <br /><br />YirePayout is supported by powerful account management features, transaction analytics and reporting tools, custom withdrawal scheduling, and intelligent dashboarding that gives you real-time insights into your payout operations. Whether you need to pay out funds to multiple recipients or a single one, YirePayout streamlines the process, saving you time and money.
                        </p>
                        <a href='/signup'>Get Started</a>
                    </div>
                </div>
            </section>

            <section className="three-box">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box" data-aos="fade-right" data-aos-duration="1000">
                                <span>Feature</span>
                                <h2>Quick Settlement</h2>
                                <p>Based upon the account type and transaction type, settlement can take between 24 and 48 hours***. </p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box">
                                <span>Feature</span>
                                <h2>Multi-Bill Pay</h2>
                                <p>Payments can be generated to support one or multiple vendors with 1 time or subscription payouts.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box" data-aos="fade-left" data-aos-duration="1000">
                                <span>Feature</span>
                                <h2>Credit & Lending</h2>
                                <p>With consideration of account type, tranaction volume, and longevity, B2B loan servicing is available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="account">
                <div className="container">
                    <div className="center-content">
                        <img src={require('../assets/images/circle-logo.png')} />
                        <h2>Start with a free <span>yirePayout Account</span></h2>
                        <p>Ready to get started? Create an account.</p>
                        <a href='/signup'>Get Started</a>
                    </div>
                </div>
            </section>

            <section className="contact-team">
                <div className="container">
                    <div className="form-section">
                        <div className="contect-head">
                            <h2>Talk to our team</h2>
                            <p>If you have any questions, comments or suggestions, please don't hesitate to contact us. We are here to help!</p>
                        </div>
                        <TextField id="demo-helper-text-misaligned-no-helper" label="First Name" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Last Name" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="E-mail" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Phone" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Company Name" className="company-input" />
                        <div className="center-butn"><Button variant="contained">Sumbit</Button></div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default YirepayoutScreen;