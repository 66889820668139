import React from 'react';
import { Navigate } from 'react-router';
import { read_cookie } from 'sfcookies';
import Signin from '../screen/signin';
import Signup from '../screen/signup';
import HomeScreen from '../screen/home';
import YirepayScreen from '../screen/yirepay';
import YirepayoutScreen from '../screen/yirepayout';
import Contact from '../screen/contact';
import Developer from '../screen/developer';
import Pricing from '../screen/pricing';
import Privacy from '../screen/privacy';
import Terms from '../screen/terms';
import Account from '../screen/account';
import Apireference from '../screen/apireference';
import GetApikey from '../screen/getApikey';
import ApiSuccess from '../screen/apiSuccess';

let routes = [
    {
        path: '/sign-in',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie == "string") {
                return <Navigate to="/" />;
            }
            else {
                return <Signin />;
            }
        }
    },
    {
        path: '/signup',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie == "string") {
                return <Navigate to="/" />;
            }
            else {
                return <Signup />;
            }
        }
    },
    {
        path: '/',
        title: 'Home',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <HomeScreen />;
            }
            else {
                return <HomeScreen />;
            }
        }
    },
    {
        path: '/yirepay',
        title: 'Yirepay',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <YirepayScreen />;
            }
            else {
                return <YirepayScreen />;
            }
        }
    },
    {
        path: '/yirepayout',
        title: 'Yirepayout',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <YirepayoutScreen />;
            }
            else {
                return <YirepayoutScreen />;
            }
        }
    },
    {
        path: '/developer',
        title: 'Developer',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <Developer />;
            }
            else {
                return <Developer />;
            }
        }
    },
    {
        path: '/contact',
        title: 'Contact',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <Contact />;
            }
            else {
                return <Contact />;
            }
        }
    },
    {
        path: '/pricing',
        title: 'Pricing',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <Pricing />;
            }
            else {
                return <Pricing />;
            }
        }
    },
    {
        path: '/privacy',
        title: 'Privacy',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <Privacy />;
            }
            else {
                return <Privacy />;
            }
        }
    },
    {
        path: '/terms',
        title: 'Terms',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <Terms />;
            }
            else {
                return <Terms />;
            }
        }
    },
    {
        path: '/account',
        title: 'Account',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <Navigate to="/sign-in" />;
            }
            else {
                return <Account />;
            }
        }
    },
    {
        path: '/apireference',
        title: 'Apireference',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <Navigate to="/sign-in" />;
            }
            else {
                return <Apireference />;
            }
        }
    },
    {
        path: '/get-api-key',
        title: 'GetApiKey',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <GetApikey />;
            }
            else {
                return <GetApikey />;
            }
        }
    },
    {
        path: '/get-key-success',
        title: 'ApiSuccess',
        component: () => {
            let cookie = read_cookie("client_api")
            if (typeof cookie !== "string") {
                return <ApiSuccess />;
            }
            else {
                return <ApiSuccess />;
            }
        }
    },
];

export default routes;