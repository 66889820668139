import React from "react";
import { Navigate } from 'react-router';
import { Link } from "react-router-dom";
import './../assets/styles/successApikey.css';



const ApiSuccess = () => {
    return (
        <div className="content">
            <div className="wrapper-1">
                <Link className="wrapper-logo" to="/"><img src={require('../assets/images/logo.png')} /></Link>
                <div className="wrapper-2">
                    <h2>Key Generating....</h2>
                    <p>The API key you requested is being generated.  </p>
                    <p>An email is being sent you you with the details of your test API key. If the email has not arrived in ten (10) minutes, please check your spam folder.  </p>
                    <Link className="go-home" type="button" to={"/"} style={{ textDecoration: "none", fontWeight: "800" }}>
                        go home
                    </Link>
                </div>
            </div>
        </div>

    );
};

export default ApiSuccess;