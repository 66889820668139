import React from "react";
import Footer from "../footer";
import Header from "../header";
import './../assets/styles/developer.css'



const Privacy = () => {

    return (

        <>
            <Header />
            <section className="page-title-custom">
                <div className="container">
                    <div className="upper-text" data-aos="fade-up" data-aos-duration="2000">
                        <img src={require('../assets/images/dev00.png')} />
                        <h1>Privacy Policy</h1>
                        <p>
                            This Privacy Policy describes how YirePay and its subsidiaries (“YirePay,” “we,” or “us”) processes personal data and other information through its websites, platforms, or mobile applications (collectively, the “Services”) by explaining what types of information YirePay collects, how that information is used, YirePay’s sharing practices, and your choices. YirePay is the controller for purposes of this Privacy Policy. This policy was revised Jan 1, 2023. <br /><br />

                            <h4>Information We Collect</h4>
                            We collect information from you directly when you provide it to us, automatically through the Services, and from third parties. Each collection mechanism is described in more detail in this Privacy Policy.<br /><br />

                            <h4>Information You Provide Directly</h4>
                            <i>-Account Registration.</i> When you set up a merchant account with us, we typically request your name, email address, social security number, business name, address, corporate entity data, and banking information. We use this information to provide you with the requested Services.<br />
                            <i>-Register for an Event.</i> When you register for an event, we collect your name and email address. We use this information to communicate with you about the event and related services.<br />
                            <i>-Schedule a Demonstration.</i> When you schedule a demonstration of our products, we ask you to provide your name, organization, email address, city, state, and telephone number. We collect this information to communicate with you and provide you with information you requested.<br />
                            <i>-Chat.</i> We collect any information that you submit through any chat functionality on our Services to respond to your questions. This chat functionality uses some automated processes to assess how we can best support your request(s).<br />
                            <i>-Support.</i> We collect any information that you provide in connection with support queries, such as contact data, any supporting documentation reflecting your support needs, and the content of any communications with us. We use this information to respond to your support needs and provide you with information.<br />
                            <i>-Apply for a Job With Us.</i> If you apply for a job with us, we collect any information you provide in connection with your application submission, including application information submitted through any third-party recruiting platforms used by us. If you register to receive email updates from us about job postings, we collect your email address in order to provide you with this information.<br />
                            <i>-Contact Us.</i> When you contact us, we ask you to provide us with your name, email address, company name, job title, phone number, and zip code. We request this information in order to effectively address your comments and questions and provide you with relevant information in response to your inquiry.<br />
                            <i>-Other.</i> Other Services we offer may request or require you to provide us with additional information, which will be described at the point of collection.<br /><br />

                            <h4>Automatically Collected Information</h4>
                            We automatically collect the following types of information when you use our Services, including:<br /><br />

                            Usage Details. We collect information about your use of our Services, including referring/exit pages, date/time stamp, and clickstream data.<br /><br />

                            Other Technical Data. We collect information about the device you use to access our Services, including your internet connection, including your internet protocol (IP) addresses, browser type, referral domain, internet service provider (ISP), and operating system.<br /><br />

                            Cookies and Similar Tracking Technologies. A cookie is a small text file that is stored on your device through your browser. The cookies used by our Services include both session-based cookies, which expire after you close your browser or turn off your computer, and persistent cookies, which remain on your device until you affirmatively remove them. Most web browsers automatically accept cookies, but you can set your browser to decline cookies.<br /><br />

                            We use this automatically collected information to analyze trends, to provide, administer, and improve our Services, to track users’ movements while using the Services, to gather demographic information about our user base, and to market our products and services and monitor interactions with our marketing communications.<br /><br />

                            <h4>Mobile Applications</h4>
                            We offer certain mobile applications, including iOS and Android applications, for download. These mobile applications may collect additional information from you. For example, our applications may ask you to turn on location services to provide you with more relevant information based on your location. Certain mobile applications may also collect your device’s unique identifier in order to tailor and improve the mobile application’s utility to you.<br /><br />

                            <h4>How We Use Your Information</h4>
                            In addition to the uses described above, we also use your information:<br /><br />

                            -to protect our interests or the interests of third parties;<br />
                            -to maintain and enhance the security of our Services;<br />
                            -to prevent abuse of our Services;<br />
                            -to comply with our legal obligations;<br />
                            -to perform or enforce our agreements;<br />
                            -for any other purpose with your consent.<br /><br />

                            <h4>Bases for Processing</h4>
                            The legal bases for our processing activities include:<br /><br />

                            -your consent;<br />
                            -YirePay exercising its rights and performing its obligations in connection with entering into or performing under any contract it makes with you;<br />
                            -compliance with our legal obligations;<br />
                            -YirePay’s legitimate interests that are not overridden by your interests or your fundamental rights and freedoms.<br /><br />


                            <h4>Third Parties</h4>
                            You may also be able to engage with our Services through social media services and through third- party social media plug-ins, integrations, and applications. When you engage with our content on or through social media services or other third-party platforms, plug-ins, integrations, or applications, you may allow us to have access to certain information in your profile and information about your interaction with our content. For a description on how social media services and other third-party platforms, plug-ins, integrations, or applications handle your information, please refer to their respective privacy policies and terms of use, which may permit you to modify your privacy settings.<br /><br />

                            Third parties may also use cookies or similar technologies on our Services for advertising, retargeting, analytics, and tracking. Please see “More About Analytics and Advertising” and “Your Choices and Rights” below for more information about third party cookies.<br /><br />

                            We share your information:<br /><br />

                            -with our affiliates in connection with your use of our Services or our business relationship with you;<br />
                            -with contractors, service providers, and other third parties we use to support our Services;<br />
                            -to fulfill any purpose for which you provide it;<br />
                            -for any other purpose disclosed to you when you provide the information; or<br />
                            -with your consent<br /><br />

                            We may also share your information:<br /><br />

                            -to comply with any court order, law, or legal process, including to respond to any government or regulatory request;<br />
                            -to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets;<br />
                            -if we believe disclosure is necessary or appropriate to protect our rights, property, or interests, or the rights, property, or interests of third parties.<br /><br />

                            <h4>More About Analytics and Advertising</h4>
                            We use third-party analytics providers to help track how users interact with our Services. Third parties may also use cookies or web pixels for online advertising personalization. Third-party tracking technologies are not controlled by us, even if they use our Services to help store or collect data. Statements regarding our practices do not apply to the methods for collecting information used by third-party advertisers and others or the use of the information that such third parties collect. The relevant third party’s terms of service, privacy policy, permissions, notices, and choices should be reviewed regarding their collection, storage, and sharing practices.<br /><br />

                            <h4>Third-Party Platforms and Social Media</h4>
                            This Privacy Policy applies only to YirePay’s Services and not to any other platforms or websites, or mobile applications operated by third parties. Our Services may contain links to other platforms, websites, and mobile applications that we believe may be of interest to you. Our Services also contain links that allow you to connect with us on social media.<br /><br />

                            YirePay is not responsible for the privacy practices of any platforms, websites, or mobile applications not controlled by us. We encourage you to review the privacy policies of any platforms, websites, or mobile applications you use outside of our Services before providing any information to or through them.<br /><br />

                            Similarly, with respect to our own mobile applications we are not responsible for any mobile application tracking or monitoring activities by third party providers such as Google, Inc., Apple, Inc., or your wireless or network carrier. You should always read and understand the policies of any third-party provider before downloading or using any mobile applications.<br /><br />

                            <h4>Data Retention</h4>
                            We only store information for as long as is needed to fulfill our contractual or legal obligations or any other permissible purpose for which we have collected it, including to prevent fraud or security incidents.<br /><br />

                            <h4>Security</h4>
                            We use commercially reasonable administrative, physical, and technical measures to safeguard your information and help protect it from unauthorized access. However, we cannot guarantee complete protection of your information since transmission via the internet is not 100% secure. You play an important role in keeping your information secure. You should not share your username, password, or other security information for your account with anyone. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us by contacting us as detailed in the “Contact Us” section.<br /><br />

                            <h4>Children</h4>
                            Our Services are not directed to children, as defined under applicable law. If you believe that a child has provided their information to us through our Services, please contact us as detailed in the “Contact Us” section, so that we can take steps to delete it.<br /><br />

                            <h4>Your Choices and Rights</h4>
                            You can control the use of cookies within your browser. However, please note that disabling some or all cookies may affect the functionality of our Services. Our Services do not respond to browser “Do Not Track” signals.<br /><br />

                            You can change the information collected through our mobile application in your device’s settings. Disabling the collection of certain information may decrease the utility of the mobile application.<br /><br />

                            We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI’s website at https://www.networkadvertising.org. You may also be able to opt out of receiving third-party behavioral ads by visiting the Digital Advertising Alliance (“DAA”) website at http://www.aboutads.info/choices. We are not responsible for effectiveness of or compliance with any third-parties’ opt-out options.<br /><br />

                            To the extent required by law, we provide you with (i) reasonable access to your personal information collected through our Services, and (ii) the ability to review, correct, export, or delete your information, restrict its processing, or object to its processing. You may exercise the foregoing rights by contacting us using any of the mechanisms detailed in the “Contact Us” section.<br /><br />

                            If you are the customer of a merchant or other organization to whom we provide products or services, please direct your request directly to that merchant or organization so that they can address it.<br /><br />
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default Privacy;