import React from "react";
import './../assets/styles/yirepay.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from "../header";
import Footer from "../footer";


const YirepayScreen = () => {

    return (

        <>
            <Header />
            <section className="page-title-custom">
                <div className="container">
                    <div className="upper-text" data-aos="fade-up" data-aos-duration="2000">

                        <img src={require('../assets/images/yirepay.png')} />
                        <h1>yirePay</h1>
                        <p>YirePay is the ultimate self-service customer merchant processor that can handle all types of payments, including crypto, through its open APIs. With YirePay, you can accept payments from your customers through multiple channels, such as online, terminal, echeck, QR codes, and contactless payments like NFC, Google Pay, and Apple Pay.<br></br><br></br>

                            In addition to these features, YirePay also provides fraud management and detection services to protect your business from high-risk transactions. You can set up recurring billing or subscriptions, loyalty programs, reward points, discount codes, and promotional offers with ease. YirePay gives you complete control over your payment processing needs, with a user-friendly interface that simplifies the process for you.
                        </p>
                        <a href='/signup'>Get Started</a>
                    </div>
                </div>
            </section>

            <section className="three-box">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box" data-aos="fade-right" data-aos-duration="1000">
                                <span>Feature</span>
                                <h2>Virtual Terminal</h2>
                                <p>Accept payments quickly directly from your dashboard. Simply enter card and address information and generate a receipt immediately.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box">
                                <span>Feature</span>
                                <h2>Subscriptions</h2>
                                <p>Generate custom subscriptions from your dashboard with options to update active subscriptions, prorate payments, and create add-ons, </p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box" data-aos="fade-left" data-aos-duration="1000">
                                <span>Feature</span>
                                <h2>Invoiving</h2>
                                <p>Send customized payment links to your customers that can be shared via URL, or easily inserted into your website with HTML code.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box" data-aos="fade-right" data-aos-duration="1000">
                                <span>Feature</span>
                                <h2>Crypto (USDC)</h2>
                                <p>Process payments for fiat currencies through a single integration. Current support for USDC, other currencies coming soon. </p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box">
                                <span>Feature</span>
                                <h2>Terminals</h2>
                                <p>Capture unlimited in-person payments with industry leading hardware terminals that offer tipping and support for EMV, swipe and NFC. </p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box" data-aos="fade-left" data-aos-duration="1000">
                                <span>Feature</span>
                                <h2>ACH Transfers</h2>
                                <p>Allow customers to pay invoices, payment links, and subscriptions via ACH. Bank details are automatically encrypted and tokenized.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box" data-aos="fade-right" data-aos-duration="1000">
                                <span>Feature</span>
                                <h2>Custom Pricing</h2>
                                <p>Based on a number of factors including industry, volume, average ticket, and transaction type, we will tailor a great rate for you.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box">
                                <span>Feature</span>
                                <h2>Security</h2>
                                <p>yirePay tokenizes sensitive credit card information so it never touches your system, thus minimizing the risk to your customers and their data.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-4 col-sm-4">
                            <div className="tag-box" data-aos="fade-left" data-aos-duration="1000">
                                <span>Feature</span>
                                <h2>Support</h2>
                                <p>With account managers to developers, we have the team to provide technical support, questions, disputes, webinars and documentation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="account">
                <div className="container">
                    <div className="center-content">
                        <img src={require('../assets/images/circle-logo.png')} />
                        <h2>Start with a free <span>yirePay Account</span></h2>
                        <p>Ready to get started? Create an account.</p>
                        <a href='/signup'>Get Started</a>
                    </div>
                </div>
            </section>

            <section className="our-steps">
                <div className="container">
                    <div className="steps-content">
                        <h2><span>yirePay Account</span></h2>
                        <p>yirePay provides great terms and the solutions you need to accept payments with ease.</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className="list-steps" data-aos="fade-up" data-aos-duration="1000">
                                <img src={require('../assets/images/card.png')} />
                                <h3>No contracts, ever!</h3>
                                <p>We won't lock you into a long term agreement. Feel free to leave our service at any point in time.</p>
                            </div>
                            <div className="list-steps" data-aos="fade-up" data-aos-duration="1500">
                                <img src={require('../assets/images/card.png')} />
                                <h3>No fluff!</h3>
                                <p>There is no fee for this and fee for that. Your rates per transaction will be clear.</p>
                            </div>
                            <div className="list-steps" data-aos="fade-up" data-aos-duration="2000">
                                <img src={require('../assets/images/card.png')} />
                                <h3>Quick settlements!</h3>
                                <p>In the dashboard, you can choose a withdrawal or settlement schedule that makes sense for your business</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="list-img" data-aos="zoom-in" data-aos-duration="1500">
                                <img src={require('../assets/images/steps-img.png')} />
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="contact-team">
                <div className="container">
                    <div className="form-section">
                        <div className="contect-head">
                            <h2>Talk to our team</h2>
                            <p>If you have any questions, comments or suggestions, please don't hesitate to contact us. We are here to help!</p>
                        </div>
                        <TextField id="demo-helper-text-misaligned-no-helper" label="First Name" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Last Name" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="E-mail" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Phone" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Company Name" className="company-input" />
                        <div className="center-butn"><Button variant="contained">Sumbit</Button></div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default YirepayScreen;