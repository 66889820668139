import React from "react";
import './../assets/styles/yirepay.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from "../header";
import Footer from "../footer";


const Contact = () => {

    return (

        <>
            <Header />


            <section className="account">
                <div className="container">
                    <div className="center-content">
                        <img src={require('../assets/images/circle-logo.png')} />
                        <h2><span>Contact</span></h2>
                        <p>info@yirepay.com <br />

                            2970 Belcrest Center Drive, Suite 105<br />
                            Hyattsville, MD 20782</p>
                        <a href='/signup'>Get Started</a>
                    </div>
                </div>
            </section>

            <section className="contact-team">
                <div className="container">
                    <div className="form-section">
                        <div className="contect-head">
                            <h2>Talk to our team</h2>
                            <p>Have a uniue situation, feel free to contact us!</p>
                        </div>
                        <TextField id="demo-helper-text-misaligned-no-helper" label="First Name" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Last Name" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="E-mail" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Phone" />
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Company Name" className="company-input" />
                        <div className="center-butn"><Button variant="contained">Sumbit</Button></div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default Contact;