import './App.css';
import { BrowserRouter, Routes , Route } from 'react-router-dom';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.min.js'
import Aos from 'aos';
import 'aos/dist/aos.css';
import routes from './model/Routes';

function App() {
  Aos.init();
  return (
    <BrowserRouter>
      <Routes>
                {routes.map((x, i) =>
                    <Route key={i} path={x.path} element={<x.component />} />
                )}
      </Routes>

    </BrowserRouter>

  );
}

export default App;
