import apiUrl from './config';

class ApiHandler {
    constructor() {
        this._url = apiUrl;
    }
    showError = (err) => {
        console.log(err)
    }
    getResult = (url, method = "GET", data = null, headers = null, success = () => { }, failed = () => { }) => {
        let parameters = {};
        parameters.method = method;
        if (data) {
            parameters.body = data;
        } if (headers) {
            parameters.headers = headers;
        }
        try {
            fetch(this._url + url, parameters)
                .then(response => response.json())
                .then(response => {
                    success(response);
                }
                ).catch((errors) => {
                    failed(errors);
                }
                );
        } catch (error) {
            failed(error.message);
        }

    }

    register = (name, mobile, address1, address2, city, state, zipCode, email, password, success = () => { }, failed = () => { }) => {
        if (!name || !mobile || !address1 || !city || !state || !zipCode || !email || !password) return;
        const authHeaders = {
            "Content-Type": "application/json"
        };
        const formData = JSON.stringify({
            client_name: name,
            client_email: email,
            client_address1: address1,
            client_address2: address2,
            client_city: city,
            client_state: state,
            client_zip: zipCode,
            client_mobile: mobile,
            client_password: password
          })
        
        this.getResult("/newclient", "POST", formData, authHeaders, (response) => {
            if (response.errors) {
                failed(response.errors[0].message)
            } else if (response.object.message) {
                success(response.object.message, response.object.data);
            } else {
                failed("something went wrong");
            }
        });
    }

    login = (email, password, success = () => { }, failed = () => { }) => {
        if (!email || !password) return;
        this.getResult("/clientlogin?client_email=" + email + "&client_password=" + password, "GET", null, null, (response) => {
            if (response.errors) {
                failed(response.errors[0].message)
            } else if (response.object.data) {
                success("You are successfully logged in", response.object.data);
            } else {
                failed("something went wrong");
            }
        });
    }

    clientBalance = (client_api, success = () => { }, failed = () => { }) => {
        if (!client_api) return;
        this.getResult("/clientbalance?client_api=" + client_api, "GET", null, null, (response) => {
            if (response.errors) {
                failed(response.errors[0].message)
            } else if (response.object.data) {
                success(response.object.message, response.object.data);
            } else {
                failed("something went wrong");
            }
        });
    }

    transactions = (client_api, page, success = () => { }, failed = () => { }) => {
        if (!client_api || !page) return;
        // const formData = {client_api, page};
        this.getResult("/transactions?client_api=" + client_api + "&page=" + page, "GET", null, null, (response) => {
            if (response.errors) {
                failed(response.errors[0].message)
            } else if (response.object.data) {
                success(response.object.message, response.object.data);
            } else {
                failed("something went wrong");
            }
        });
    }

    withdrawal = (client_api, amount, success = () => { }, failed = () => { }) => {
        if (!client_api || !amount) return;
        console.log(client_api)
        this.getResult("/withdrawal?client_api=" + client_api + "&amount=" + amount, "GET", null, null, (response) => {
            if (response.errors) {
                failed(response.errors[0].message)
            } else if (response.object.data) {
                success(response.object.message, response.object.data);
            } else {
                failed("something went wrong");
            }
        });
    }
}

export default ApiHandler;
